import { Theme } from '@mui/material';
import { StatusEnum, TimeEntry, WorkingDay } from '../../api';
import { Row } from '../../contexts/TimesheetContext';
import dayjs, { Dayjs } from 'dayjs';

export const isSpecificDay = (date: Date, targetDay: number): boolean =>
  date.getDay() === targetDay;

export const formatDateInGerman = (date: Date) => {
  // Format weekday and date
  const weekday = date.toLocaleDateString('de-DE', { weekday: 'short' });
  const formattedDate = date
    .toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit' })
    .replace(/\//g, '.'); // Replace '/' with '.' for '25.05'

  return {
    weekday,
    date: formattedDate,
  };
};

export function shiftDateByOneMonthMinusOneDay(inputDate: Date): Date {
  // Create a new date object to avoid mutating the input date
  const date = new Date(inputDate);

  // Add one month to the date
  date.setMonth(date.getMonth() + 1);

  // Subtract one day from the date
  date.setDate(date.getDate() - 1);

  // Return the adjusted Date object
  return date;
}

export const findNextSunday = (date: Date): Date => {
  const dayOfWeek = date.getDay(); // Get the current day of the week (0 = Sunday, ..., 6 = Saturday)
  const daysUntilNextSunday = (7 - dayOfWeek) % 7; // Calculate days until the next Sunday
  const nextSunday = new Date(date); // Create a new Date object to avoid mutating the input date
  nextSunday.setDate(date.getDate() + daysUntilNextSunday); // Set the date to the next Sunday
  return nextSunday;
};
export const getBackgroundColor = (state: StatusEnum, theme: Theme) => {
  switch (state) {
    case StatusEnum.APPROVED:
      return theme.palette.custom.green;
    case StatusEnum.LOCKED:
      return theme.palette.custom.grey;
    case StatusEnum.WARNING:
      return theme.palette.custom.orange;
    case StatusEnum.ERROR:
      return theme.palette.custom.red;
    case StatusEnum.GOOD:
      return theme.palette.custom.blue;
    default:
      return 'transparent'; // Default background
  }
};

export const computeRows = (
  startDate: Dayjs,
  endDate: Dayjs,
  data: WorkingDay[],
): Row[] => {
  let currentDate = dayjs(startDate);
  let index = 0;
  const result: Row[] = [];
  while (currentDate <= endDate) {
    const isWeekend =
      // TODO: only use Dayjs not Date
      isSpecificDay(currentDate.toDate(), 0) ||
      isSpecificDay(currentDate.toDate(), 6);
    if (index < data.length) {
      const dayData: WorkingDay = data[index];

      const row: Row = {
        from: null,
        to: null,
        date: dayjs(currentDate),
        pause: dayjs(currentDate).startOf('day'),
        state: dayData.status || StatusEnum.UNKNOWN,
        block: isWeekend,
        holiday: '',
        workingHours:
          (dayData.totalHours || 0) >= 0 ? dayData.totalHours || 0 : 0,
        type: 1,
        info: dayData.info || '',
      };
      for (const workEntry of dayData.entries) {
        const project = workEntry.project?.id;
        const startTime = dayjs(workEntry.startTime);
        const endTime = dayjs(workEntry.endTime);
        switch (project) {
          case 'SAPLING_PROJECT_BREAK': {
            const differenceInMilliseconds =
              endTime.toDate().getTime() - startTime.toDate().getTime();

            row.pause = dayjs(differenceInMilliseconds).subtract(1, 'hour');
            break;
          }
          case 'SAPLING_PROJECT_HOLIDAY': {
            row.holiday = dayData.info || '';
            break;
          }
          case 'SAPLING_PROJECT_VACATION': {
            row.type = 2;
            break;
          }
          case 'SAPLING_PROJECT_SICK': {
            row.type = 3;
            break;
          }
          case 'SAPLING_PROJECT_UNPAIDLEAVE': {
            row.type = 4;
            break;
          }
          case 'SAPLING_PROJECT_WORK_UNSPECIFIED': {
            row.type = 1;
            row.from = startTime;
            row.to = endTime;
            break;
          }
          case 'SAPLING_PROJECT_ACC_BALANCE_OVERHANG': {
            // Ignore this in terms of row type
          }
        }
      }
      result.push(row);
    } else {
      const row: Row = {
        from: null,
        to: null,
        date: dayjs(currentDate),
        pause: dayjs(currentDate).startOf('day'),
        state: StatusEnum.UNKNOWN,
        block: isWeekend,
        holiday: '',
        workingHours: 0,
        type: 1,
        info: '',
      };
      result.push(row);
    }
    // Move to the next day
    currentDate = currentDate.add(1, 'day');
    index++;
  }
  return result;
};

export function formatDate(date: Date): string {
  // Get the local year, month, and day
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
  const day = String(date.getDate()).padStart(2, '0');

  // Format the date as YYYY-MM-DD
  return `${year}-${month}-${day}`;
}

export const convertRowsToWorkingDays = (
  rowsToConvert: Row[],
): WorkingDay[] => {
  const result: WorkingDay[] = [];
  rowsToConvert.forEach((row) => {
    const startTime = row.from
      ? row.from
          .set('year', row.date.year())
          .set('month', row.date.month())
          .set('date', row.date.date())
      : null;
    const endTime = row.to
      ? row.to
          .set('year', row.date.year())
          .set('month', row.date.month())
          .set('date', row.date.date())
      : null;

    const validStartTime = startTime !== null && startTime.isValid();
    const validEndTime = endTime !== null && endTime.isValid();
    const datestamp = row.date.format('YYYY-MM-DD');

    if (row.type === 1) {
      const workEntry: TimeEntry = {
        project: { id: 'SAPLING_PROJECT_WORK_UNSPECIFIED', name: 'Arbeit' },
        startTime: validStartTime ? startTime.format() : undefined,
        endTime: validEndTime ? endTime.format() : undefined,
      };
      const timeEntries: TimeEntry[] = [workEntry];

      //TODO: currently pause timeentry is set manually. the user should be able to set a start and end value himself
      // TODO: As long as 'breakOnlySum' is enabled, Pause is always from 00:00 until length of pause
      const pause =
        row.pause !== null && row.pause.isValid() && row.pause?.hour() !== 0
          ? row.pause
              .set('year', row.date.year())
              .set('month', row.date.month())
              .set('date', row.date.date())
          : null;
      if (pause !== null && pause !== undefined) {
        const pauseEntry: TimeEntry = {
          project: { id: 'SAPLING_PROJECT_BREAK', name: 'Pause' },
          startTime: pause.set('hour', 0).set('minute', 0).format(),
          endTime: pause.format(),
        };
        timeEntries.push(pauseEntry);
      }
      const workDay: WorkingDay = {
        datestamp: datestamp,
        entries: timeEntries,
        breakOnlySum: true,
      };
      result.push(workDay);
    } else if (row.type === 2) {
      const workEntry: TimeEntry = {
        project: { id: 'SAPLING_PROJECT_VACATION', name: 'Urlaub' },
        startTime: undefined,
        endTime: undefined,
      };
      const workDay: WorkingDay = {
        datestamp: datestamp,
        entries: [workEntry],
        breakOnlySum: true,
      };
      result.push(workDay);
    } else if (row.type === 3) {
      const workEntry: TimeEntry = {
        project: { id: 'SAPLING_PROJECT_SICK', name: 'Krank' },
        startTime: undefined,
        endTime: undefined,
      };
      const workDay: WorkingDay = {
        datestamp: datestamp,
        entries: [workEntry],
        breakOnlySum: true,
      };
      result.push(workDay);
    } else if (row.type === 4) {
      const workEntry: TimeEntry = {
        project: { id: 'SAPLING_PROJECT_UNPAIDLEAVE', name: 'Abwesend' },
        startTime: undefined,
        endTime: undefined,
      };
      const workDay: WorkingDay = {
        datestamp: datestamp,
        entries: [workEntry],
        breakOnlySum: true,
      };
      result.push(workDay);
    }
  });
  return result;
};

export function getMonthAndYearInGerman(date: Date): string {
  const monthsInGerman = [
    'Januar', // January
    'Februar', // February
    'März', // March
    'April', // April
    'Mai', // May
    'Juni', // June
    'Juli', // July
    'August', // August
    'September', // September
    'Oktober', // October
    'November', // November
    'Dezember', // December
  ];

  const monthIndex = date.getMonth(); // getMonth() returns 0-based index (0 for January, 1 for February, etc.)
  const year = date.getFullYear(); // Get the full year

  return `${monthsInGerman[monthIndex]} ${year}`;
}

export const convertToHourMinute = (hours: number): string => {
  // Separate the hours and minutes
  const fullHours = Math.floor(hours);
  const minutes = Math.round((hours - fullHours) * 60);

  // Round minutes to the nearest 15-minute increment
  const roundedMinutes = Math.round(minutes / 15) * 15;

  // If minutes reach 60, add 1 hour and set minutes to 0
  if (roundedMinutes === 60) {
    return `${fullHours + 1}h 00m`;
  }

  return `${fullHours}h ${roundedMinutes}m`;
};

import React, { useCallback, useRef } from 'react';
import '../../Timesheet.css';
import './PauseCell.css';
import { TimeField } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

const PauseCell = ({
  index,
  isDisabled,
  selectedTime,
  setSelectedTime,
}: {
  index: number;
  isDisabled: boolean;
  selectedTime: Dayjs | null;
  setSelectedTime: (index: number, newTime: Dayjs | null) => void;
}) => {
  const debounceTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  // Handle time change with debounce
  const handleTimeChange = useCallback(
    (newTime: Dayjs | null) => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }

      debounceTimer.current = setTimeout(() => {
        if (newTime && newTime.isValid()) {
          setSelectedTime(index, newTime);
        }
      }, 600);
    },
    [index, setSelectedTime],
  );

  return (
    <td className={'no-column-divider pause'}>
      <TimeField
        value={selectedTime}
        onChange={handleTimeChange}
        ampm={false}
        disabled={isDisabled}
        className="pause-cell-input"
      />
    </td>
  );
};

export default PauseCell;

import React, { FC, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Chip,
  IconButton,
  useTheme,
  useMediaQuery,
  Tooltip,
} from '@mui/material';
import { User, UserService } from '../../api';
import UserEditPopup from './UserEditPopup';
import UserAvatar from '../UserAvatar';
import dayjs from 'dayjs';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import EditIcon from '@mui/icons-material/Edit';
import { SxProps, Theme } from '@mui/system';
const UserOverview: FC = () => {
  const [userData, setUserData] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  useEffect(() => {
    UserService.getUsers()
      .then((response) => {
        setUserData(response);
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });
  }, []);

  const handleRowClick = (user: User) => {
    setSelectedUser(user);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedUser(null);
  };

  const handleSaveUser = (updatedUser: User) => {
    setUserData((prevUserData) =>
      prevUserData.map((user) =>
        user.uuid === updatedUser.uuid ? updatedUser : user,
      ),
    );
  };
  const handleDeleteUser = (user: User) => {
    setUserData((prevUserData) =>
      prevUserData.filter((u) => u.uuid !== user.uuid),
    );
  };

  const startDate = dayjs().startOf('month').format('YYYY-MM-DD');
  const endDate = dayjs().endOf('month').format('YYYY-MM-DD');

  const handleOpenLink = (userUuid: string) => {
    const url = `/trackMonthly/${startDate}/${endDate}/${userUuid}`;
    window.open(url, '_blank');
  };
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const tableBodyCellSx = { padding: '16px 8px' };
  const tableHeadCellSx = {
    fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1.0rem' },
    fontWeight: 'bold',
    padding: '16px 8px',
  };
  const tableBodyTypoSx = (user: User): SxProps<Theme> => {
    return {
      fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1.0rem' },
      overflowWrap: 'break-word',
      wordBreak: 'break-word',
      color: user.status === 'DELETED' ? 'lightgrey' : '',
    };
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px',
      }}
    >
      <TableContainer
        component={Paper}
        elevation={3}
        sx={{
          width: '100%',
          maxWidth: '1200px',
          borderRadius: '12px',
          overflow: 'hidden',
          padding: '16px',
        }}
      >
        <Typography variant="h5" gutterBottom align="center" fontWeight="bold">
          Mitarbeiterverwaltung
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={tableHeadCellSx}></TableCell>
              {isDesktop && <TableCell sx={tableHeadCellSx}>Name</TableCell>}
              <TableCell sx={tableHeadCellSx}>Email</TableCell>
              <TableCell sx={tableHeadCellSx}>Rollen</TableCell>

              <TableCell sx={tableHeadCellSx}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData.map((user, index) => (
              <TableRow key={index}>
                <TableCell sx={tableBodyCellSx}>
                  {user.status === 'DELETED' ? (
                    ''
                  ) : (
                    <UserAvatar user={user} style={{ width: 40, height: 40 }} />
                  )}
                </TableCell>
                {isDesktop && (
                  <TableCell sx={tableBodyCellSx}>
                    <Typography sx={tableBodyTypoSx(user)}>
                      {user.firstName + ' ' + user.lastName}
                    </Typography>
                  </TableCell>
                )}
                <TableCell sx={tableBodyCellSx}>
                  <Typography sx={tableBodyTypoSx(user)}>
                    {user.mail}
                  </Typography>
                </TableCell>
                <TableCell sx={{ padding: '16px 8px', width: '20%' }}>
                  {user.roles.map((role, j) => (
                    <Chip
                      key={j}
                      label={role}
                      color={
                        role === 'ADMIN' ||
                        role === 'WERKSTUD' ||
                        role === 'HR' ||
                        role === 'BOARD' ||
                        role === 'EMPLOYEE'
                          ? 'success'
                          : 'default'
                      }
                      sx={{
                        marginRight: '4px',
                        backgroundColor: getChipBackgroundColor(
                          user.status,
                          role,
                        ),
                        color: 'white',
                        margin: '4px',
                        fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' },
                      }}
                    />
                  ))}
                </TableCell>

                <TableCell sx={tableBodyCellSx}>
                  <Tooltip title="Bearbeite Nutzer">
                    <IconButton
                      color="primary"
                      onClick={() => handleRowClick(user)}
                      disabled={user.status === 'DELETED'}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  {user?.roles.find((role) => role === 'WERKSTUD') !==
                    undefined && (
                    <Tooltip title="Öffne Timesheet für diesen Nutzer">
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenLink(user.uuid || '')}
                        disabled={user.status === 'DELETED'}
                      >
                        <PunchClockIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedUser && (
        <UserEditPopup
          user={selectedUser}
          open={isPopupOpen}
          onClose={handleClosePopup}
          onSave={handleSaveUser}
          onDelete={handleDeleteUser}
        />
      )}
    </div>
  );
};

// Helper function to determine background color
const getChipBackgroundColor = (status: string | undefined, role: string) => {
  if (status !== undefined && status === 'DELETED') {
    return 'lightgrey';
  }

  if (['ADMIN', 'HR', 'BOARD'].includes(role)) {
    return '#ffb642'; // nterra secondary does not work, hardcode (?)
  }

  if (role === 'DEFAULT') {
    return 'grey';
  }

  return 'primary';
};

export default UserOverview;

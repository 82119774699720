import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Typography,
  Button,
  Box,
} from '@mui/material';
import { AccountingOverview, AccountingService } from '../../api';
import StatusIconComponent from './StatusIconComponent';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import UserAvatar from '../UserAvatar';
import { useSnackbar } from '../../contexts/SnackbarProvider';
import { convertToHourMinute } from '../Timesheet/tool';
import CancelIcon from '@mui/icons-material/Cancel';

const CustomTable = ({
  accountingOverview,
  billable,
}: {
  accountingOverview: AccountingOverview;
  billable: boolean;
}) => {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // State for countdown timer
  const [timeLeft, setTimeLeft] = useState(300); // 300 seconds = 5 minutes

  // Start the countdown timer when component is mounted and billable is true
  useEffect(() => {
    if (billable) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            handleCancel();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(timer); // Cleanup timer on component unmount
    }
  }, [billable]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const handleClick = (uuid: string) => {
    const formattedStartDate = dayjs(accountingOverview?.startDate).format(
      'YYYY-MM-DD',
    );
    const formattedEndDate = dayjs(accountingOverview?.endDate).format(
      'YYYY-MM-DD',
    );
    window.open(
      `/trackMonthly/${formattedStartDate}/${formattedEndDate}/${uuid}`,
      '_blank',
    );
  };

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
    };
    return new Date(dateString).toLocaleDateString('de-DE', options);
  };

  // Handle cancel action
  const handleCancel = () => {
    showSnackbar('Abrechnung abgebrochen');
    navigate('/');
    AccountingService.cancelAccounting(accountingOverview.accountingHash || '');
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px',
      }}
    >
      <TableContainer
        component={Paper}
        elevation={3}
        sx={{
          width: '100%',
          maxWidth: '600px',
          borderRadius: '12px',
          overflow: 'hidden',
          padding: '16px',
        }}
      >
        <Typography variant="h5" gutterBottom align="center" fontWeight="bold">
          Abrechnung ({accountingOverview.grouprole})
        </Typography>
        <Typography variant="h5" gutterBottom align="center">
          {formatDate(accountingOverview?.startDate || '')} -{' '}
          {formatDate(accountingOverview?.endDate || '')}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />

              <TableCell sx={{ fontWeight: 'bold', padding: '16px 8px' }}>
                Name
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', padding: '16px 8px' }}>
                Summe
              </TableCell>
              <TableCell sx={{ padding: '16px 8px', color: 'silver' }}>
                Eigentlich
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', padding: '16px 8px' }}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accountingOverview?.timesheetSummaries ? (
              accountingOverview.timesheetSummaries.map((row, index) => (
                <TableRow
                  key={index}
                  hover
                  sx={{
                    cursor: 'pointer',
                    transition: 'background-color 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    },
                  }}
                  onClick={() => handleClick(row.user.uuid || '')}
                >
                  <TableCell sx={{ padding: '16px 8px' }}>
                    {row.user ? (
                      <UserAvatar
                        user={row.user}
                        style={{ width: 40, height: 40 }}
                      />
                    ) : (
                      <Avatar sx={{ width: 40, height: 40 }} />
                    )}
                  </TableCell>

                  <TableCell sx={{ padding: '16px 8px' }}>
                    <Typography
                      sx={{
                        overflowWrap: 'break-word',
                        wordBreak: 'break-word',
                      }}
                    >
                      {row.user.firstName + ' ' + row.user.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: '16px 8px' }}>
                    {convertToHourMinute(row.effectiveHours!)}
                  </TableCell>
                  <TableCell sx={{ padding: '16px 8px', color: 'silver' }}>
                    {convertToHourMinute(row.totalHours)}
                  </TableCell>
                  <TableCell sx={{ padding: '16px 8px' }}>
                    <StatusIconComponent status={row.status} />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography>Keine Daten verfügbar</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {billable && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={2}
          >
            <Button
              variant="contained"
              fullWidth
              style={{
                height: '50px',
                fontSize: '14px',
                fontWeight: 'bold',
                marginRight: '16px',
              }}
              onClick={() => {
                AccountingService.confirmAccounting(
                  accountingOverview?.accountingHash || '',
                )
                  .then(() => {
                    showSnackbar('Abrechnung erfolgreich!');
                    navigate('/');
                  })
                  .catch((error) =>
                    showSnackbar('An error occurred: ', 'error', error),
                  );
              }}
            >
              Abrechnen
            </Button>

            <Button
              variant="contained"
              color="error"
              style={{
                width: '80px',
                height: '50px',
                padding: 0,
                minWidth: '80px',
              }}
              onClick={handleCancel}
            >
              <CancelIcon />
              <Typography
                variant="caption"
                sx={{ marginLeft: '8px', fontSize: '14px' }}
              >
                {formatTime(timeLeft)}
              </Typography>
            </Button>
          </Box>
        )}
      </TableContainer>
    </div>
  );
};

export default CustomTable;

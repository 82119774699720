import React, { useCallback } from 'react';
import '../../Timesheet.css';
import { Button } from '@mui/material';
import { useWindowWidth } from '../../../../contexts/WindowWidthContext';
import { convertToHourMinute } from '../../tool';
import { useDrawer } from '../../../../contexts/DrawerContext';
import './WorkTimeCell.css';

const WorkTimeCell = React.memo(
  ({
    index,
    workSum,
    isDisabled,
    toggleDrawer,
  }: {
    index: number;
    workSum: number;
    isDisabled: boolean;
    toggleDrawer: () => void;
  }) => {
    const { isType } = useWindowWidth();
    const { setPressedIndexRow } = useDrawer();

    const handleClick = useCallback(() => {
      toggleDrawer();
      setPressedIndexRow(index);
    }, [index, toggleDrawer, setPressedIndexRow]);

    const isDesktop = isType('desktop');

    return (
      <td className="no-column-divider work">
        <Button
          onClick={handleClick}
          disabled={isDisabled || isDesktop}
          variant={isDesktop ? 'text' : 'outlined'}
          className={`work-time-button ${isDisabled ? 'disabled' : ''}`}
        >
          {convertToHourMinute(workSum)}
        </Button>
      </td>
    );
  },
);
WorkTimeCell.displayName = 'WorkTimeCell';
export default WorkTimeCell;

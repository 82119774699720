import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  styled,
} from '@mui/material';
import { User, UserService } from '../../api';
import UserAvatar from '../UserAvatar';
import { useSnackbar } from '../../contexts/SnackbarProvider';

interface UserEditPopupProps {
  user: User;
  open: boolean;
  onClose: () => void;
  onSave: (updatedUser: User) => void;
  onDelete: (user: User) => void;
}

const provinces = [
  'Baden-Württemberg',
  'Bayern',
  'Berlin',
  'Brandenburg',
  'Bremen',
  'Hamburg',
  'Hessen',
  'Niedersachsen',
  'Mecklenburg-Vorpommern',
  'Nordreihn-Westfalen',
  'Reihnland-Pfalz',
  'Saarland',
  'Sachsen',
  'Sachsen-Anhalt',
  'Schleswig-Holstein',
  'Thüringen',
];
const UnderlinedText = styled('span')(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
  '&::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: -2, // Position the underline just below the text
    width: '100%',
    height: '4px', // Thickness of the underline
    backgroundColor: theme.palette.primary.main, // Primary color
    borderRadius: '2px', // Rounded corners
  },
}));

const UserEditPopup: FC<UserEditPopupProps> = ({
  user,
  open,
  onClose,
  onSave,
  onDelete,
}) => {
  const { showSnackbar } = useSnackbar();
  const [editableUser, setEditableUser] = useState<User>({
    ...user,
    allowedWorkingHours: {
      nightAllowed: user.allowedWorkingHours?.nightAllowed ?? false,
      weekendAllowed: user.allowedWorkingHours?.weekendAllowed ?? false,
      holidayAllowed: user.allowedWorkingHours?.holidayAllowed ?? false,
      weekMax: user.allowedWorkingHours?.weekMax ?? 0,
    },
  });

  const [roleOptions, setRoleOptions] = useState<string[]>([]);
  useEffect(() => {
    UserService.getAllRoles().then((x) => setRoleOptions(x));
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditableUser({ ...editableUser, [name]: value });
  };

  const handleProvinceChange = (event: SelectChangeEvent<string>) => {
    setEditableUser({ ...editableUser, province: event.target.value });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setEditableUser((prevUser) => ({
      ...prevUser,
      allowedWorkingHours: {
        ...prevUser.allowedWorkingHours!,
        [name]: checked,
      },
    }));
  };

  const handleWeekMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEditableUser((prevUser) => ({
      ...prevUser,
      allowedWorkingHours: {
        ...prevUser.allowedWorkingHours!,
        weekMax: parseInt(value, 10) || 0,
      },
    }));
  };

  const handleRoleToggle = (role: string) => {
    setEditableUser((prevUser) => {
      const roles = prevUser.roles.includes(role)
        ? prevUser.roles.filter((r) => r !== role)
        : [...prevUser.roles, role];
      return { ...prevUser, roles };
    });
  };

  const handleSave = () => {
    UserService.updateUser(editableUser.uuid!, editableUser)
      .then((updatedUser) => {
        showSnackbar('Saved changes', 'success');
        onSave(updatedUser);
        onClose();
      })
      .catch((error) => {
        showSnackbar('Error updating user:' + error, 'error');
      });
  };

  const handleDelete = () => {
    if (
      window.confirm(
        `Sicher, dass du den Nutzer ${editableUser.firstName} ${editableUser.lastName} löschen möchtest?`,
      )
    ) {
      UserService.deleteUser(editableUser.uuid!)
        .then(() => {
          console.log('delete');
          onDelete(editableUser);
          onClose();
        })
        .catch((error) => {
          console.error('Error deleting user:', error);
        });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '16px',
          }}
        >
          <UserAvatar
            user={editableUser}
            style={{ width: 80, height: 80, marginRight: '16px' }}
          />
          <Typography variant="h6">
            <UnderlinedText>
              {editableUser.firstName} {editableUser.lastName}
            </UnderlinedText>
          </Typography>
        </div>
        <TextField
          margin="dense"
          label="Vorname"
          name="firstName"
          value={editableUser.firstName}
          onChange={handleInputChange}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Nachname"
          name="lastName"
          value={editableUser.lastName}
          onChange={handleInputChange}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Email"
          name="mail"
          value={editableUser.mail}
          onChange={handleInputChange}
          fullWidth
        />{' '}
        <FormControl fullWidth sx={{ marginTop: '8px' }}>
          <Select
            labelId="demo-simple-select-label"
            margin="dense"
            name="province"
            value={editableUser.province || ''}
            onChange={handleProvinceChange}
            fullWidth
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select Province
            </MenuItem>
            {provinces.map((province) => (
              <MenuItem key={province} value={province}>
                {province}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ marginTop: '16px' }}>
          <Typography variant="subtitle1">
            Darf an folgenden Zeiten arbeiten:
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={editableUser.allowedWorkingHours!.nightAllowed}
                onChange={handleCheckboxChange}
                name="nightAllowed"
              />
            }
            label="Nachts"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={editableUser.allowedWorkingHours!.weekendAllowed}
                onChange={handleCheckboxChange}
                name="weekendAllowed"
              />
            }
            label="Wochenende"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={editableUser.allowedWorkingHours!.holidayAllowed}
                onChange={handleCheckboxChange}
                name="holidayAllowed"
              />
            }
            label="Feiertage"
          />
          <TextField
            margin="dense"
            label="Stunden pro Woche"
            type="number"
            value={editableUser.allowedWorkingHours!.weekMax}
            onChange={handleWeekMaxChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Offener Stundenübertrag"
            type="number"
            value={editableUser.pendingMinutes || 0}
            fullWidth
            disabled={true}
          />
        </Box>
        <Box sx={{ marginTop: '16px' }}>
          <Typography variant="subtitle1">Rollen:</Typography>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '8px',
              marginTop: '8px',
            }}
          >
            {roleOptions.map((role) => (
              <Chip
                key={role}
                label={role}
                color={
                  editableUser.roles.includes(role) ? 'success' : 'default'
                }
                onClick={() => handleRoleToggle(role)}
                style={{
                  backgroundColor: editableUser.roles.includes(role)
                    ? 'green'
                    : 'grey',
                  color: 'white',
                  cursor: 'pointer',
                }}
              />
            ))}
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDelete} color="error">
          Löschen
        </Button>
        <Button onClick={onClose} color="secondary">
          Abbrechen
        </Button>
        <Button onClick={handleSave} color="primary">
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserEditPopup;

import React from 'react';
import Section from './Section';
import { Typography } from '@mui/material';
import src from '../../../images/demo_usermanagement.png';

const UserManagement = ({
  index,
  buttonDisabled,
}: {
  index: number;
  buttonDisabled: boolean;
}) => {
  return (
    <Section
      index={index}
      title="Nutzerverwaltung"
      madeFor="Systemadministrator"
      buttonLink="/useroverview"
      imageSrc={src}
      buttonDisabled={buttonDisabled}
    >
      <Typography
        variant="body1"
        sx={{
          fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
          marginBottom: '1rem',
        }}
      >
        Der Systemadminstrator kann Nutzer und dessen Zugriffsrechte verwalten.
        HR hat hier die Möglichkeit, besondere Arbeitszeitregelungen pro Nutzer
        festzulegen.
      </Typography>
    </Section>
  );
};

export default UserManagement;

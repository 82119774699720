/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AllowedWorkingHours } from './AllowedWorkingHours';
export type User = {
    /**
     * Unique identifier for the user
     */
    readonly uuid?: string;
    /**
     * Email address of user
     */
    mail: string;
    /**
     * First name(s) of user
     */
    firstName: string;
    /**
     * Last name(s) of user
     */
    lastName: string;
    /**
     * URL to the user's avatar image
     */
    readonly avatarLink?: string;
    /**
     * Roles of the user in the organization
     */
    roles: Array<string>;
    /**
     * Province of the user's main residence
     */
    province?: string;
    allowedWorkingHours?: AllowedWorkingHours;
    /**
     * "ACTIVE","DELETED","INVITED","PENDING"
     */
    readonly status?: User.status;
    /**
     * Minutes that were not paid in previous accountings
     */
    pendingMinutes?: number;
};
export namespace User {
    /**
     * "ACTIVE","DELETED","INVITED","PENDING"
     */
    export enum status {
        ACTIVE = 'ACTIVE',
        DELETED = 'DELETED',
        INVITED = 'INVITED',
        PENDING = 'PENDING',
    }
}


import React, { ReactNode, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import SignIn from '../SignIn/SignIn';
import backgroundImage from '../../images/pattern_big.png';
import ParkIcon from '@mui/icons-material/Park';
import { useUser } from '../../contexts/UserContext';
import AlertComponent from '../landing_page/AlertComponent';
import WarningComponent from '../landing_page/WarningComponent';
import {
  IconButton,
  useMediaQuery,
  useTheme,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Divider,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HistoryIcon from '@mui/icons-material/History';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import GroupIcon from '@mui/icons-material/Group';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { consentGiven } from '../legal/CookieConsent';
import NoCookies from '../SignIn/NoCookies';
interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { user } = useUser();
  const theme = useTheme();
  const desktopMenu = useMediaQuery(theme.breakpoints.up('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  // Menu items (shared between desktop and mobile)
  const menuItems: (JSX.Element | undefined)[] = [];
  if (user?.roles.find((role) => role === 'ADMIN' || role === 'HR')) {
    menuItems.push(
      <Button
        component={Link}
        to="/accountingoverview"
        style={{ color: 'white', marginLeft: '20px', minWidth: 'auto' }}
        key="accountingoverview"
      >
        <Typography variant="button" noWrap>
          Abrechnungshistorie
        </Typography>
      </Button>,
    );
    menuItems.push(
      <Button
        component={Link}
        to="/hrprompt"
        style={{ color: 'white', marginLeft: '20px', minWidth: 'auto' }}
        key="hrprompt"
      >
        <Typography variant="button" noWrap>
          Abrechnen
        </Typography>
      </Button>,
    );
    menuItems.push(
      <Button
        component={Link}
        to="/useroverview"
        style={{ color: 'white', marginLeft: '20px', minWidth: 'auto' }}
        key="useroverview"
      >
        <Typography variant="button" noWrap>
          Nutzerverwaltung
        </Typography>
      </Button>,
    );
  }
  if (user?.roles.find((role) => role === 'WERKSTUD' || role === 'EMPLOYEE')) {
    menuItems.push(
      <Button
        component={Link}
        to="/trackMonthly"
        style={{ color: 'white', marginLeft: '20px', minWidth: 'auto' }}
        key="trackMonthly"
      >
        <Typography variant="button" noWrap>
          Zeiterfassung
        </Typography>
      </Button>,
    );
  }

  const iconStyle = { color: theme.palette.primary.main, margin: '10px' };

  const getMenuIcon = (key: string | null | undefined) => {
    switch (key) {
      case 'accountingoverview':
        return <HistoryIcon style={iconStyle}></HistoryIcon>;
      case 'hrprompt':
        return <AccountBalanceIcon style={iconStyle}></AccountBalanceIcon>;
      case 'useroverview':
        return <GroupIcon style={iconStyle}></GroupIcon>;
      case 'trackMonthly':
        return <MoreTimeIcon style={iconStyle}></MoreTimeIcon>;
      default:
        return <ParkIcon style={iconStyle}></ParkIcon>;
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
      }}
    >
      <CssBaseline />
      <AppBar position="sticky">
        <Toolbar
          style={{
            justifyContent: 'space-between',
            display: 'flex',
            flexWrap: 'nowrap',
            overflow: 'hidden',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!desktopMenu && menuItems.length > 0 && (
              // Render hamburger icon for mobile
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon style={{ color: 'white', margin: '10px' }} />
              </IconButton>
            )}
            <Button
              component={Link}
              to="/"
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              <Typography variant="h5" style={{ color: 'white' }}>
                S
              </Typography>
              <ParkIcon style={{ color: 'white', margin: '-2px' }} />
              <Typography variant="h5" style={{ color: 'white' }}>
                PLING
              </Typography>
            </Button>
            {desktopMenu &&
              // Render menu buttons directly for desktop
              menuItems}
          </div>
          {consentGiven && <SignIn />}
          {!consentGiven && <NoCookies />}
        </Toolbar>
      </AppBar>

      {/* Drawer for mobile menu */}
      {!desktopMenu && (
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          PaperProps={{
            sx: {
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: 'cover',
            },
          }}
        >
          <List>
            {menuItems.map((item, index) => (
              <div key={index}>
                <ListItem
                  disablePadding
                  style={{ marginRight: '10px' }}
                  onClick={toggleDrawer(false)}
                >
                  <ListItemButton component={Link} to={item?.props.to}>
                    <ListItemIcon>{getMenuIcon(item?.key)}</ListItemIcon>
                    <ListItemText primary={item?.props.children} />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </div>
            ))}
          </List>
        </Drawer>
      )}

      <div
        style={{
          flexGrow: 1,
          marginTop: '80px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Container maxWidth="lg" style={{ padding: '0px' }}>
          {/* Conditional Alert for No Permission */}
          {user?.roles.find((x) => x === 'DEFAULT') &&
            user?.roles.length == 1 && <AlertComponent />}
          {!user && <WarningComponent />}
          {children}
        </Container>
      </div>

      <footer
        style={{
          padding: '10px',
          backgroundColor: '#f5f5f5',
          marginTop: 'auto',
          width: '100%',
          flexShrink: 0,
        }}
      >
        <Container
          maxWidth="lg"
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center', // Align items vertically
          }}
        >
          <div>
            <Button component={Link} to="/" style={{ color: 'inherit' }}>
              Home
            </Button>
            <Button component={Link} to="/info" style={{ color: 'inherit' }}>
              Information
            </Button>
            <Button
              component={Link}
              to="https://nterra.com"
              style={{ color: 'inherit' }}
            >
              Nterra
            </Button>
            <Button
              component={Link}
              to="/impressum"
              style={{ color: 'inherit' }}
            >
              Impressum
            </Button>
          </div>
          <Typography>
            &copy; {new Date().getFullYear() !== 2024 ? '2024-' : ''}{' '}
            {new Date().getFullYear()} nterra
          </Typography>
        </Container>
      </footer>
    </div>
  );
};

export default Layout;

import React from 'react';
import { Container, Typography } from '@mui/material';
import TimesheetDemo from './sections/DemoTimesheet';
import TimeTrackingDemo from './sections/TimeTrackingDemo';
import UserManagement from './sections/UserManagementDemo';
import DeveloperOverview from './DeveloperOverview';
import { useUser } from '../../contexts/UserContext';
import AccountingTableDemo from './sections/AccountingTableDemo';
import WelcomeComponent from './WelcomeComponent';

const LandingPage = () => {
  const { user } = useUser();
  return (
    <Container maxWidth="lg" sx={{ padding: '2rem' }}>
      {user?.mail === 'paul.adminmann@nterra.com' && <WelcomeComponent />}
      {/* Main Title */}
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        sx={{
          fontWeight: 'bold',
          marginBottom: '3rem',
          marginTop: '3rem',
          fontSize: { xs: '2.5rem', sm: '3rem', md: '3.5rem' },
        }}
      >
        Was kann Sapling?
      </Typography>

      <TimesheetDemo
        index={0}
        buttonDisabled={
          user?.roles.find((role) => role === 'WERKSTUD') === undefined
        }
      />
      <TimeTrackingDemo
        index={1}
        buttonDisabled={
          user?.roles.find((role) => role === 'HR' || role === 'ADMIN') ===
          undefined
        }
      />
      <AccountingTableDemo
        index={2}
        buttonDisabled={
          user?.roles.find((role) => role === 'HR' || role === 'ADMIN') ===
          undefined
        }
      />
      <UserManagement
        index={3}
        buttonDisabled={
          user?.roles.find((role) => role === 'HR' || role === 'ADMIN') ===
          undefined
        }
      />

      <DeveloperOverview />
    </Container>
  );
};

export default LandingPage;

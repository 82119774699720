import React from 'react';
import Section from './Section';
import { Typography } from '@mui/material';
import src from '../../../images/demo_abrechnung_overview.png';

const AccountingTableDemo = ({
  index,
  buttonDisabled,
}: {
  index: number;
  buttonDisabled: boolean;
}) => {
  return (
    <Section
      index={index}
      title="Abrechnungshistorie"
      madeFor="HR"
      buttonLink="/accountingoverview"
      imageSrc={src}
      buttonDisabled={buttonDisabled}
    >
      <Typography
        variant="body1"
        sx={{
          fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
          marginBottom: '1rem',
        }}
      >
        In diesem Bereich können HR-Mitarbeitende die Abrechnungshistorie
        einsehen und vergangene Abrechnungen detailliert nachvollziehen.
      </Typography>
    </Section>
  );
};

export default AccountingTableDemo;

import React from 'react';
import './Timesheet.css';
import { Paper } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MobileTimeSheetDrawer from './mobileComponents/timesheetDrawer/MobileTimeSheetDrawer';
import ExtraTextRow from './tableComponents/rows/ExtraTextRow';
import WeekTitleRow from './tableComponents/rows/WeekTitleRow';
import { useDrawer } from '../../contexts/DrawerContext';
import DefaultRow from './tableComponents/rows/DefaultRow';
import HeaderRow from './tableComponents/rows/HeaderRow';
import { useTimesheet } from '../../contexts/TimesheetContext';
import { StatusEnum } from '../../api';
import {
  convertToHourMinute,
  findNextSunday,
  getBackgroundColor,
  isSpecificDay,
} from './tool';
import { useWindowWidth } from '../../contexts/WindowWidthContext';
import { useTheme } from '@mui/material';
const TrackMonthly = ({ weeklySum = true }: { weeklySum?: boolean }) => {
  const { rows, updateFrom, updateNote, updatePause, updateTo } =
    useTimesheet();
  const { isType } = useWindowWidth();
  const { toggleDrawer } = useDrawer();

  const theme = useTheme();
  const needsWarningRow = (state: StatusEnum) => {
    return state === StatusEnum.WARNING || state === StatusEnum.ERROR;
  };

  // Accumulate weekly hours
  let weeklyWorkingMinutes = 0;
  let totalWorkingHours = 0;
  let approvedWorkingHours = 0;
  let leftOverWorkingHours = 0;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Paper
        style={{
          border: '1px solid #ccc',
          minWidth: '300px',
          margin: '20px',
          borderRadius: '12px',
        }}
        className="main"
      >
        <div>
          <table className="table">
            <thead>
              <HeaderRow />
            </thead>
            <tbody>
              {rows.map((row, index) => {
                const isMonday = isSpecificDay(row.date.toDate(), 1);
                const isSunday = isSpecificDay(row.date.toDate(), 0);
                const isLastDay = index >= rows.length - 1;
                // Start of the week, reset the weekly total
                if (isMonday) weeklyWorkingMinutes = 0;

                // Accumulate working hours for the week
                weeklyWorkingMinutes += row.workingHours;
                totalWorkingHours += row.workingHours;

                if (row.state === StatusEnum.APPROVED)
                  approvedWorkingHours += row.workingHours;
                else leftOverWorkingHours += row.workingHours;

                return (
                  <React.Fragment key={index}>
                    {/* weekly title */}
                    {(isMonday || index === 0) && weeklySum && (
                      <WeekTitleRow
                        start={row.date.toDate()}
                        end={findNextSunday(row.date.toDate())}
                      />
                    )}

                    {/* default row */}
                    <DefaultRow
                      index={index}
                      row={row}
                      updateFrom={updateFrom}
                      updateTo={updateTo}
                      updateNote={updateNote}
                      updatePause={updatePause}
                      isTypeDesktop={isType('desktop')}
                      toggleDrawer={toggleDrawer}
                    />

                    {/* error row */}
                    {needsWarningRow(row.state) && !row.block && (
                      <ExtraTextRow
                        text={row.info}
                        color={getBackgroundColor(row.state, theme)}
                      />
                    )}

                    {/* Display weekly working hours total on Sunday */}
                    {(isSunday || isLastDay) && weeklySum && (
                      <ExtraTextRow
                        text={`Σ = ${convertToHourMinute(weeklyWorkingMinutes)}`}
                        alignTextLeft={false}
                      />
                    )}

                    {isLastDay && weeklySum && (
                      <>
                        <tr>
                          <td className="column-divider datum" />
                          <td colSpan={7} />
                        </tr>
                        <ExtraTextRow
                          text={`Abgerechnete Stunden = ${convertToHourMinute(approvedWorkingHours)}`}
                          alignTextLeft={false}
                        />
                        <ExtraTextRow
                          text={`Noch offende Stunden = ${convertToHourMinute(leftOverWorkingHours)}`}
                          alignTextLeft={false}
                        />
                        <ExtraTextRow
                          text={`Gesamtarbeitszeit = ${convertToHourMinute(totalWorkingHours)}`}
                          alignTextLeft={false}
                        />
                      </>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </Paper>
      <MobileTimeSheetDrawer />
    </LocalizationProvider>
  );
};

export default TrackMonthly;

import React from 'react';
import './Timesheet.css';
import TrackMonthly from './Timesheet';
import { TimesheetProvider } from '../../contexts/TimesheetContext';
import TrackMonthlyBanner from './TimesheetBanner';
import { DrawerProvider } from '../../contexts/DrawerContext';

const TrackMonthlyRoot = () => {
  return (
    <TimesheetProvider>
      <div className="root">
        <TrackMonthlyBanner />
        <div className="banner">
          <DrawerProvider>
            <TrackMonthly />
          </DrawerProvider>
        </div>
      </div>
    </TimesheetProvider>
  );
};

export default TrackMonthlyRoot;
